// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-a-1-404-jsx": () => import("./../../../src/pages/a1/404.jsx" /* webpackChunkName: "component---src-pages-a-1-404-jsx" */),
  "component---src-pages-a-1-index-jsx": () => import("./../../../src/pages/a1/index.jsx" /* webpackChunkName: "component---src-pages-a-1-index-jsx" */),
  "component---src-pages-a-2-app-index-jsx": () => import("./../../../src/pages/a2/app/index.jsx" /* webpackChunkName: "component---src-pages-a-2-app-index-jsx" */),
  "component---src-pages-a-2-consulting-index-jsx": () => import("./../../../src/pages/a2/consulting/index.jsx" /* webpackChunkName: "component---src-pages-a-2-consulting-index-jsx" */),
  "component---src-pages-a-2-fintech-index-jsx": () => import("./../../../src/pages/a2/fintech/index.jsx" /* webpackChunkName: "component---src-pages-a-2-fintech-index-jsx" */),
  "component---src-pages-a-2-marketing-index-jsx": () => import("./../../../src/pages/a2/marketing/index.jsx" /* webpackChunkName: "component---src-pages-a-2-marketing-index-jsx" */),
  "component---src-pages-a-2-saas-index-jsx": () => import("./../../../src/pages/a2/saas/index.jsx" /* webpackChunkName: "component---src-pages-a-2-saas-index-jsx" */),
  "component---src-pages-a-2-saas-v-2-index-jsx": () => import("./../../../src/pages/a2/saas-v2/index.jsx" /* webpackChunkName: "component---src-pages-a-2-saas-v-2-index-jsx" */),
  "component---src-pages-a-3-about-us-01-index-jsx": () => import("./../../../src/pages/a3/about-us-01/index.jsx" /* webpackChunkName: "component---src-pages-a-3-about-us-01-index-jsx" */),
  "component---src-pages-a-3-about-us-02-index-jsx": () => import("./../../../src/pages/a3/about-us-02/index.jsx" /* webpackChunkName: "component---src-pages-a-3-about-us-02-index-jsx" */),
  "component---src-pages-a-3-about-us-03-index-jsx": () => import("./../../../src/pages/a3/about-us-03/index.jsx" /* webpackChunkName: "component---src-pages-a-3-about-us-03-index-jsx" */),
  "component---src-pages-a-3-pricing-01-index-jsx": () => import("./../../../src/pages/a3/pricing-01/index.jsx" /* webpackChunkName: "component---src-pages-a-3-pricing-01-index-jsx" */),
  "component---src-pages-a-3-pricing-02-index-jsx": () => import("./../../../src/pages/a3/pricing-02/index.jsx" /* webpackChunkName: "component---src-pages-a-3-pricing-02-index-jsx" */),
  "component---src-pages-a-3-pricing-03-index-jsx": () => import("./../../../src/pages/a3/pricing-03/index.jsx" /* webpackChunkName: "component---src-pages-a-3-pricing-03-index-jsx" */),
  "component---src-pages-a-3-pricing-04-index-jsx": () => import("./../../../src/pages/a3/pricing-04/index.jsx" /* webpackChunkName: "component---src-pages-a-3-pricing-04-index-jsx" */),
  "component---src-pages-a-3-pricing-05-index-jsx": () => import("./../../../src/pages/a3/pricing-05/index.jsx" /* webpackChunkName: "component---src-pages-a-3-pricing-05-index-jsx" */),
  "component---src-pages-a-3-services-01-index-jsx": () => import("./../../../src/pages/a3/services-01/index.jsx" /* webpackChunkName: "component---src-pages-a-3-services-01-index-jsx" */),
  "component---src-pages-a-3-services-02-index-jsx": () => import("./../../../src/pages/a3/services-02/index.jsx" /* webpackChunkName: "component---src-pages-a-3-services-02-index-jsx" */),
  "component---src-pages-a-3-services-03-index-jsx": () => import("./../../../src/pages/a3/services-03/index.jsx" /* webpackChunkName: "component---src-pages-a-3-services-03-index-jsx" */),
  "component---src-pages-a-3-sss-index-jsx": () => import("./../../../src/pages/a3/sss/index.jsx" /* webpackChunkName: "component---src-pages-a-3-sss-index-jsx" */),
  "component---src-pages-corporate-about-index-jsx": () => import("./../../../src/pages/corporate/about/index.jsx" /* webpackChunkName: "component---src-pages-corporate-about-index-jsx" */),
  "component---src-pages-corporate-chatbot-price-index-jsx": () => import("./../../../src/pages/corporate/chatbot_price/index.jsx" /* webpackChunkName: "component---src-pages-corporate-chatbot-price-index-jsx" */),
  "component---src-pages-corporate-contract-1-index-jsx": () => import("./../../../src/pages/corporate/contract1/index.jsx" /* webpackChunkName: "component---src-pages-corporate-contract-1-index-jsx" */),
  "component---src-pages-corporate-contract-2-index-jsx": () => import("./../../../src/pages/corporate/contract2/index.jsx" /* webpackChunkName: "component---src-pages-corporate-contract-2-index-jsx" */),
  "component---src-pages-corporate-marketing-price-index-jsx": () => import("./../../../src/pages/corporate/marketing_price/index.jsx" /* webpackChunkName: "component---src-pages-corporate-marketing-price-index-jsx" */),
  "component---src-pages-corporate-services-01-index-jsx": () => import("./../../../src/pages/corporate/services-01/index.jsx" /* webpackChunkName: "component---src-pages-corporate-services-01-index-jsx" */),
  "component---src-pages-corporate-services-02-index-jsx": () => import("./../../../src/pages/corporate/services-02/index.jsx" /* webpackChunkName: "component---src-pages-corporate-services-02-index-jsx" */),
  "component---src-pages-corporate-services-03-index-jsx": () => import("./../../../src/pages/corporate/services-03/index.jsx" /* webpackChunkName: "component---src-pages-corporate-services-03-index-jsx" */),
  "component---src-pages-corporate-social-price-index-jsx": () => import("./../../../src/pages/corporate/social_price/index.jsx" /* webpackChunkName: "component---src-pages-corporate-social-price-index-jsx" */),
  "component---src-pages-corporate-sss-index-jsx": () => import("./../../../src/pages/corporate/sss/index.jsx" /* webpackChunkName: "component---src-pages-corporate-sss-index-jsx" */),
  "component---src-pages-corporate-whatsapp-price-1-index-jsx": () => import("./../../../src/pages/corporate/whatsapp_price1/index.jsx" /* webpackChunkName: "component---src-pages-corporate-whatsapp-price-1-index-jsx" */),
  "component---src-pages-corporate-whatsapp-price-2-index-jsx": () => import("./../../../src/pages/corporate/whatsapp_price2/index.jsx" /* webpackChunkName: "component---src-pages-corporate-whatsapp-price-2-index-jsx" */),
  "component---src-pages-hizmetlerimiz-index-jsx": () => import("./../../../src/pages/hizmetlerimiz/index.jsx" /* webpackChunkName: "component---src-pages-hizmetlerimiz-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-products-chatbot-index-jsx": () => import("./../../../src/pages/products/chatbot/index.jsx" /* webpackChunkName: "component---src-pages-products-chatbot-index-jsx" */),
  "component---src-pages-products-consulting-index-jsx": () => import("./../../../src/pages/products/consulting/index.jsx" /* webpackChunkName: "component---src-pages-products-consulting-index-jsx" */),
  "component---src-pages-products-live-support-index-jsx": () => import("./../../../src/pages/products/live_support/index.jsx" /* webpackChunkName: "component---src-pages-products-live-support-index-jsx" */),
  "component---src-pages-products-marketing-index-jsx": () => import("./../../../src/pages/products/marketing/index.jsx" /* webpackChunkName: "component---src-pages-products-marketing-index-jsx" */),
  "component---src-pages-products-saas-v-2-index-jsx": () => import("./../../../src/pages/products/saas-v2/index.jsx" /* webpackChunkName: "component---src-pages-products-saas-v-2-index-jsx" */),
  "component---src-pages-products-whatsapp-business-index-jsx": () => import("./../../../src/pages/products/whatsapp_business/index.jsx" /* webpackChunkName: "component---src-pages-products-whatsapp-business-index-jsx" */)
}

